.videoContainer {
	h2 {
		color: $color1;
		font-size: 76px;
		font-weight: 300;
		text-align: center;
		strong {
			font-weight: 600;
		}
		@media (max-width: 600px) {
			font-size: 65px;
		}
	}
	.vPlaceholder {
		max-width: 732px;
		width: 100%;
		margin: 0 auto;
		display: block;
		margin-top: 32px;
	}
}
