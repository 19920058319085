.homePage {
	.mainContainer {
		background-size: 100%;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: top center;
		padding-top: 1024px;
		@media (max-width: 1440px) {
			padding-top: 580px;
		}
		@media (max-width: 600px) {
			padding-top: 227px;
			background-attachment: scroll;
		}
		.contentContainer {
			background-color: $bgColor;
			box-shadow: 0px -30px 40px rgba(0, 0, 0, 0.1);
			border-radius: 60px 60px 0px 0px;
			width: 100%;
			padding: 130px 20px 0;
			z-index: 4;
			@media (max-width: 600px) {
				border-radius: 0;
				padding: 50px 20px 0;
			}
			.welcomeBox {
				width: 100%;
				max-width: 732px;
				margin: 0 auto;

				h1 {
					font-weight: 300;
					text-align: center;
					color: $fontColor;
					font-size: 77px;
					letter-spacing: 0.3em;
					padding: 0;
					margin-block-start: 0;
					margin-block-end: 0;
					margin-inline-start: 0;
					margin-inline-end: 0;
					@media (max-width: 600px) {
						font-size: 35px;
					}
				}
				.hLogo {
					max-width: 732px;
					width: 100%;
					margin: 0 auto 38px;
				}
				.colTM {
					width: 100%;
					height: auto;
					margin-bottom: 20px;
				}
				p {
					font-weight: 400;
					font-size: 22px;
					color: $fontColor;
					margin-bottom: 44px;
					@media (max-width: 600px) {
						font-size: 18px;
					}
				}
			}
		}
	}
}
