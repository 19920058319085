// SCSS SYNTAX
// %message-shared {
//     border: 1px solid #ccc;
//     padding: 10px;
//     color: #333;
//   }

//   .message {
//     @extend %message-shared;
//   }

%shadow {
	-webkit-box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 30px 40px rgba(0, 0, 0, 0.1);
}

%shadow2 {
	-webkit-box-shadow: 0px -30px 70px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px -30px 70px rgba(0, 0, 0, 0.1);
	box-shadow: 0px -30px 70px rgba(0, 0, 0, 0.1);
}

%listMargin {
	list-style: none;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 0px;
}
