.roadmap {
	max-width: 732px;
	width: 100%;
	margin: 70px auto;

	h2 {
		font-size: 146px;
		font-weight: 600;
		color: $color1;
		@media (max-width: 600px) {
			font-size: 70px;
		}
	}
	h3 {
		margin-top: 30px;
		font-weight: 600;
		color: $fontColor;
		font-size: 48px;
	}
	ul {
		margin-top: 20px;
		margin-left: 20px;
		margin-bottom: 50px;
		color: $fontColor;
		li {
			font-size: 24px;
			@media (max-width: 600px) {
				font-size: 18px;
			}
		}
	}
	.percentages {
		@include centerBox();
		.numberP {
			width: 200px;
			font-size: 48px;
			font-weight: 600;
			line-height: 56px;
			@media (max-width: 600px) {
				text-align: center;
			}
		}
		.listDesc {
			margin-left: 20px;
			width: 464px;
			li {
				font-size: 24px;
				@media (max-width: 600px) {
					font-size: 18px;
				}
			}
		}
	}
	.roadmap2 {
		width: 100%;
		h4 {
			font-size: 36px;
			margin-top: 20px;
		}
		p {
			margin: 20px 0;
			font-size: 24px;
			color: rgba(0, 0, 0, 0.6);
			font-weight: 400;
			font-family: $fontFamily;
			@media (max-width: 600px) {
				font-size: 18px;
			}
		}
		.coffeeImage {
			display: block;
			text-align: center;
			cursor: pointer;
			img {
				max-width: 350px;
				width: 100%;
				margin: 50px auto;
			}
		}
	}
}
