.aboutTheArt {
	max-width: 732px;
	margin: 100px auto;
	h2 {
		color: $color1;
		font-size: 76px;
		font-weight: 300;
		text-align: center;
		margin-bottom: 30px;
		strong {
			font-weight: 600;
		}
		@media (max-width: 600px) {
			font-size: 65px;
		}
	}

	p {
		font-size: 24px;
		color: $fontColor;
		font-weight: 400;
		margin-bottom: 48px;
		strong {
			font-weight: 600;
		}
		@media (max-width: 600px) {
			font-size: 18px;
		}
	}
	.lineHr {
		max-width: 228px;
		height: 2px;
		background-color: $fontColor;
		border-color: $fontColor;
		border-bottom: 0;
		border-left: 0;
		border-right: 0;
		margin-bottom: 50px;
	}
}
